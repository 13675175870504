<template>
  <v-row justify="center">
    <v-dialog
      v-model="showForm"
      width="600px"
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <v-container class="pa-0">
        <v-card class="pb-10">
          <v-system-bar
            height="47"
            :color="
              form.status == 'Active' || empty(form.status)
                ? '#aeddff'
                : '#ff5252'
            "
          >
            <v-icon @click="close">mdi-close</v-icon>
            <h3
              :class="form.status == 'Active' ? 'black--text' : 'white--text'"
            >
              {{ title }}
            </h3>
            <span
              class="ml-3 mt-1 white--text blink"
              v-if="form.status == 'Disabled'"
            >
              RECORD IS DISABLED
            </span>
            <v-spacer></v-spacer>
            <v-btn
              title
              color="primary"
              v-if="allownew && view != 'new'"
              @click="newForm"
              elevation="0"
              class="mr-2"
            >
              <v-icon color="white">mdi-plus</v-icon>
              New
            </v-btn>
            <v-btn
              title
              color="warning"
              v-if="allowEdit && !empty(form.id) && view == 'view'"
              @click="view = 'edit'"
              elevation="0"
            >
              <v-icon color="white">mdi-pencil</v-icon>
              Edit
            </v-btn>
            <v-btn
              title
              color="warning"
              v-if="allowEdit && view != 'view'"
              @click="cancelForm"
              elevation="0"
            >
              <v-icon color="white">mdi-cancel</v-icon>
              Cancel
            </v-btn>
            <v-btn
              title
              color="success"
              class="ml-2"
              v-if="
                (allowEdit || allownew) && (view == 'new' || view == 'edit')
              "
              @click="saveForm"
              elevation="0"
            >
              <v-icon color="white">mdi-content-save</v-icon>
              Save
            </v-btn>
            <v-btn
              title
              color="error"
              class="ml-2"
              v-if="
                allowDisable &&
                !empty(form.id) &&
                form.status == 'Active' &&
                view == 'view'
              "
              @click="disableRecord(false)"
              elevation="0"
            >
              <v-icon color="white">mdi-close-circle</v-icon>
              Disable
            </v-btn>
            <v-btn
              title
              color="success"
              class="ml-2"
              v-if="
                allowDisable &&
                !empty(form.id) &&
                form.status == 'Disabled' &&
                view == 'view'
              "
              @click="disableRecord(true)"
              elevation="0"
            >
              <v-icon color="white">mdi-close-circle</v-icon>
              Enable
            </v-btn>
          </v-system-bar>
          <v-divider />
          <v-card-text>
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              label-width="190px"
              label-position="top"
              class="demo-ruleForm"
            >
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <el-form-item label="Date" prop="date">
                    <el-date-picker
                      v-model="form.date"
                      format="yyyy-MM-dd"
                      size="mini"
                      type="date"
                      placeholder="Pick a day"
                      :disabled="view != 'new' && view != 'edit'"
                      :picker-options="futurePickerOptions"
                    ></el-date-picker>
                  </el-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <el-form-item label="Start Time" prop="start_time">
                    <el-time-picker
                      v-model="form.start_time"
                      size="mini"
                      placeholder="Pick a time"
                      format="HH:mm"
                      :disabled="view != 'new' && view != 'edit'"
                    ></el-time-picker>
                  </el-form-item>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <el-form-item label="End Time" prop="end_time">
                    <el-time-picker
                      v-model="form.end_time"
                      size="mini"
                      placeholder="Pick a time"
                      format="HH:mm"
                      :disabled="view != 'new' && view != 'edit'"
                    ></el-time-picker>
                  </el-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <el-form-item label="Maximum Capacity" prop="max_capacity">
                    <el-input-number
                      v-model="form.max_capacity"
                      size="mini"
                      :controls="false"
                      placeholder="Enter Numer"
                      :disabled="view != 'new' && view != 'edit'"
                    ></el-input-number>
                  </el-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <el-form-item label="Header Text" prop="custom_header">
                    <el-input
                      size="mini"
                      type="textarea"
                      :rows="3"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.custom_header"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <el-form-item label="Footer Text" prop="custom_footer">
                    <el-input
                      size="mini"
                      type="textarea"
                      :rows="3"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.custom_footer"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <el-form-item
                    label="Booking Confirmation Message"
                    prop="booking_confirmed_message"
                  >
                    <el-input
                      size="mini"
                      type="textarea"
                      :rows="3"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.booking_confirmed_message"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </v-card-text>
          <!--<v-divider />-->
        </v-card>
        <v-overlay :value="loading" :opacity="0.7">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-container>
    </v-dialog>
  </v-row>
</template>
<script>
import Vue from "vue";
import {
  Button,
  Form,
  FormItem,
  Input,
  InputNumber,
  Dialog,
  DatePicker,
  TimePicker,
} from "element-ui";
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Button);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Dialog);
Vue.use(DatePicker);
Vue.use(TimePicker);
export default {
  created() {},
  watch: {
    show: function () {
      this.showForm = this.show;
      if (!this.empty(this.perm)) this.permissions = this.perm;
      if (this.showForm) {
        if (this.empty(this.data)) {
          this.newForm();
        } else {
          this.view = "view";
        }
      }
    },
    data: function () {
      if (!this.empty(this.data)) {
        this.form.events_id = this.data.id;
      }
    },
    perm: function () {
      this.permissions = this.perm;
    },
  },
  props: {
    data: null,
    show: {
      type: Boolean,
      default: false,
    },
    perm: null,
    popup: {
      type: Boolean,
      default: false,
    },
  },
  newd() {},
  components: {},
  data() {
    return {
      end_point: "events/events/",
      title: "Event Dates",
      form: {
        id: "",
        events_id: null,
        date: null,
        start_time: null,
        end_time: null,
        max_capacity: null,
        custom_header: null,
        custom_footer: null,
        booking_confirmed_message: null,
        users_id: "",
        status_id: "",
        newd_at: "",
        updated_at: "",
      },
      view: "view",
      rules: {
        date: [
          { required: true, message: "Please select Date", trigger: "blur" },
        ],
        start_time: [
          {
            required: true,
            message: "Please select Start Time",
            trigger: "blur",
          },
        ],
        end_time: [
          {
            required: true,
            message: "Please select End Time",
            trigger: "blur",
          },
        ],
      },
      permissions: [],
      loading: false,
      showForm: false,
    };
  },
  methods: {
    saveForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    submit() {
      this.loading = true;
      var api = "create-event-date";
      if (!this.empty(this.form.id)) api = "update-event-date";
      this.form.date = this.formatDate(this.form.date);
      this.form.start_time = this.formatTime(this.form.start_time);
      this.form.end_time = this.formatTime(this.form.end_time);
      this.api(this.end_point + api, this.form)
        .then((response) => {
          this.loading = false;
          this.view = "view";
          this.showInfo("Record Saved successfully");
          this.$emit(response.data.dates);
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    newForm() {
      this.form = {
        id: "",
        events_id: null,
        date: null,
        start_time: null,
        end_time: null,
        max_capacity: null,
        custom_header: null,
        custom_footer: null,
        booking_confirmed_message: null,
        users_id: "",
        status_id: "",
        newd_at: "",
        updated_at: "",
      };
      this.view = "new";
    },
    close() {
      this.$emit("close");
    },
    cancelForm() {
      this.view = "view";
      if (this.popup) this.close();
    },
  },
  computed: {
    allownew() {
      return (
        this.permissions.filter((e) => e.permission == "Create").length > 0
      );
    },
    allowEdit() {
      return this.permissions.filter((e) => e.permission == "Edit").length > 0;
    },
    allowDisable() {
      return (
        this.permissions.filter((e) => e.permission == "Disable").length > 0
      );
    },
  },
  name: "EventDates",
};
</script>
<style scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 150px !important;
}
</style>
